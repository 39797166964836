import React, { useState } from "react"
import "./CardEarnVideos.css"
import TextBoldParagraph from "../../atomos/TextBoldParagraph"
import TextRegularLabel from "../../atomos/TextRegularLabel"
import ImageRedCoins from "../../atomos/ImageRedCoins"
import ImageRedExp from "../../atomos/ImageRedExp"
import TextBoldLabel from "../../atomos/TextBoldLabel"
import { PlayCircleOutlined } from '@ant-design/icons';
import { Flex, Modal } from "antd"
import ReactPlayer from 'react-player'

export default function CardEarnVideos({ title, description, image, platform, totalPoints, videoUrl }) {

    const colors = require("../../../styles/Colors.json")
    const refVideo = React.createRef();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
        try {
            refVideo?.current?.getInternalPlayer()?.pauseVideo();
        } catch (error) {
            console.log(error);
        }
    };
    const handleCancel = () => {
        setIsModalOpen(false);
        try {
            refVideo?.current?.getInternalPlayer()?.pauseVideo();
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <>
            <div className="div-card-earn-videos" onClick={showModal}>
                <div className="div-card-earn-videos-image">
                    <img src={image} alt="logo" className="img-card-earn-videos" />
                </div>
                <div className="div-card-earn-videos-info">
                    <div className="div-card-earn-videos-info-one">
                        <div className="div-logo-card-earn-videos">
                            <img src={"./images/Marca R_RC_Small.png"} className="logo-card-earn-videos" />
                        </div>
                    </div>
                    <div className="div-card-earn-videos-info-two">
                        <TextBoldParagraph text={title} colorText={"white"} />
                        <div>
                            {platform && (
                                <>
                                    <TextBoldLabel text={"Plataforma"} colorText={"white"} />
                                    <TextBoldLabel text={" "} colorText={"white"} />
                                    <TextBoldLabel text={platform} colorText={colors.BaseSlot2} />
                                </>
                            )}
                        </div>
                    </div>
                    {totalPoints && (
                        <div className="div-card-earn-videos-info-three">
                            <div className="div-win-at-card">
                                <TextBoldLabel text={"Ganhe até"} colorText={"white"} />
                                <TextBoldParagraph text={totalPoints} colorText={"white"} />
                            </div>
                            <div className="div-win-at-card" style={{ marginLeft: 5 }}>
                                <ImageRedExp />
                            </div>
                        </div>
                    )}
                </div>
                <PlayCircleOutlined
                    style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                    }} />
            </div>
            <Modal
                centered
                width={{
                    xs: '90%',
                    sm: '80%',
                    md: '70%',
                    lg: '60%',
                    xl: '50%',
                    xxl: '40%',
                }}
                open={isModalOpen}
                onOk={handleOk}
                cancelButtonProps={{ style: { display: "none" } }}
                okText={"Fechar"}
                okButtonProps={{ style: { backgroundColor: colors.BaseSlot2, color: "white" } }}
                onCancel={handleCancel}>
                <Flex>
                    <ReactPlayer ref={refVideo} url={videoUrl} controls />
                </Flex>
            </Modal>
        </>
    )
}