import React, { useEffect, useState } from "react";
import "./PendingUsersRequests.css";
import {
  List,
  Button,
  Avatar,
  Modal,
  ConfigProvider,
  QRCode,
  Alert,
} from "antd";
import {
  CheckOutlined,
  CopyOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import { AccountService, OpenAPI } from "red-canids-client-module/client-web";
import TextBoldParagraph from "../../atomos/TextBoldParagraph";
import { Flex } from "antd";
import RedStaffIcon from "../../atomos/RedStaffIcon";
import { Pagination, notification } from 'antd';

export default function PendingUsersRequests({ setTotalPending, setTotalAccepted, setTotalRequests }) {
  const [selectedUser, setSelectedUser] = useState(null);
  const [users, setUsers] = useState([]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [currentSize, setCurrentSize] = useState(10);
  const [totalItems, setTotalItems] = useState(0);

  const [api, contextHolder] = notification.useNotification();

  const openNotificationSending = () => {
    api.open({
      message: 'Enviando email',
      description:
        'O email está sendo enviado para o usuário selecionado.',
      duration: 5,
      type: "info"
    });
  };

  const openNotification = () => {
    api.open({
      message: 'Email enviado com sucesso',
      description:
        'O email foi enviado com sucesso para o usuário selecionado.',
      duration: 3,
      type: "success"
    });
  };

  const openNotificationError = () => {
    api.open({
      message: 'Email não enviado',
      description:
        'O email não foi enviado para o usuário selecionado.',
      duration: 3,
      type: "error"
    });
  };

  const colors = require("../../../styles/Colors.json");

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const reloadData = () => {
    AccountService.returnAllAccountManagerv2(currentPage, currentSize).then((res) => {
      const resData = res.data.users_to_send_email_welcome;
      setTotalItems(res.data.total_users_to_send_email);
      setTotalPending(res.data.total_accounts_pending);
      setTotalAccepted(res.data.total_accounts_active);
      setTotalRequests(res.data.total_users_to_send_email);
      setUsers(resData);
    });
  }

  useEffect(() => {
    setUsers([]);
    AccountService.returnAllAccountManagerv2(currentPage, currentSize).then((res) => {
      const resData = res.data.users_to_send_email_welcome;
      setTotalItems(res.data.total_users_to_send_email);
      setTotalPending(res.data.total_accounts_pending);
      setTotalAccepted(res.data.total_accounts_active);
      setTotalRequests(res.data.total_users_to_send_email);
      setUsers(resData);
    });
  }, []);

  const handleAccept = (user) => {
    setSelectedUser(user);
    setIsModalOpen(true);
  };

  const handleReject = (user) => {
    Modal.error({});
    setSelectedUser(null);
  };

  const listConfig = {
    dataSource: users.sort((a, b) => new Date(b.created_at) - new Date(a.created_at)),
    renderItem: (user) => (
      <List.Item
        className="list-layout"
        style={{ width: "100%" }}
        actions={[
          <Button
            type="primary"
            icon={<CheckOutlined />}
            onClick={() => {
              OpenAPI.BASE = "https://red-canids-api-2-eccxbwf4atg7a8cw.canadacentral-01.azurewebsites.net/";
              // setSelectedUser(user);
              // handleAccept(user);
              openNotificationSending();
              AccountService.sendEmailWelcome(user._id.$oid).then(() => {
                // Alert.success("Email enviado com sucesso");
                openNotification();
              }).catch((err) => {
                // Alert.error("Erro ao enviar email");
                openNotificationError();
              }).finally(() => {
                OpenAPI.BASE = "https://red-canids-api.azurewebsites.net/";
                reloadData();
              });
            }}
          >
            Enviar Email
          </Button>,
          <Button
            type="primary"
            icon={<CheckOutlined />}
            onClick={() => {
              setSelectedUser(user);
              handleAccept(user);
            }}
          >
            Gerar Link de Acesso
          </Button>,
          <Button
            icon={<CheckCircleOutlined />}
            disabled={disabled}
            onClick={() => {
              setDisabled(true);
              AccountService.updateAccountStaff(user._id.$oid)
                .then(() => {
                  window.location.reload();
                })
                .catch((err) => console.error(err));
            }}
          >
            Verificar RED Staff
          </Button>,
        ]}
      >
        <List.Item.Meta
          avatar={
            <img
              src="https://redcanidsstorage.blob.core.windows.net/redcanids/Logo_RC_Big.png"
              width="50px"
            />
          }
          title={
            <>
              <Flex align="center" justify="center" gap={10}>
                <p>{user.name}</p>
                <p>-</p>
                <p>{new Date(user?.created_at).toLocaleDateString()} {new Date(user?.created_at).toLocaleTimeString()}</p>
                {user.is_staff && <RedStaffIcon width={22} height={22} />}
              </Flex>
            </>
          }
          description={user?.email}
        />
      </List.Item>
    ),
  };

  return (
    <>
      {contextHolder}
      <ConfigProvider
        theme={{
          components: {
            Modal: {
              contentBg: "white",
            },
            Segmented: {
              itemActiveBg: "white",
              itemSelectedBg: "#ffffff",

              colorBgLayout: "#f5f5f5",
            },
          },
        }}
      >
        <Flex vertical style={{ width: "100%" }}>
          {/* <TextBoldH5
            text={"Utilizadores pendentes"}
            colorText={colors.GrayscaleSlot5}
          /> */}
          <Pagination
            defaultCurrent={currentPage}
            pageSizeOptions={[10, 20, 50, 100, 1000, 5000]}
            onShowSizeChange={(current, size) => {
              setCurrentPage(current);
              setCurrentSize(size);
              setUsers([]);
              AccountService.returnAllAccountManagerv2(current, size).then((res) => {
                const resData = res.data.users_to_send_email_welcome;
                setTotalItems(res.data.total_users_to_send_email);
                setUsers(resData);
              });
            }}
            total={totalItems}
            onChange={(page) => {
              setCurrentPage(page);
              setUsers([]);
              AccountService.returnAllAccountManagerv2(page, 10).then((res) => {
                const resData = res.data.users_to_send_email_welcome;
                setTotalItems(res.data.total_users_to_send_email);
                setUsers(resData);
              });
            }} />
          <List style={{ width: "100%" }} {...listConfig} loading={!users?.length > 0} />
        </Flex>

        <Modal
          title="Link de Acesso"
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          width={1000}
          closable={false}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              gap: 20,
            }}
          >
            <Alert
              message="Atenção, o link abaixo é único e intransferível. Envie-o apenas para o usuário selecionado por qrcode ou copie e cole o link."
              type="warning"
            />
            <QRCode
              value={
                "https://" +
                window.location.host +
                "/" +
                btoa(
                  JSON.stringify({
                    email: selectedUser?.email,
                    code_access: selectedUser?.code_access,
                  })
                )
              }
            />
            <Flex
              style={{
                width: "100%",
              }}
              align="center"
              justify="center"
            >
              <Flex>
                <TextBoldParagraph
                  text={
                    "https://" +
                    window.location.host +
                    "/" +
                    btoa(
                      JSON.stringify({
                        email: selectedUser?.email,
                        code_access: selectedUser?.code_access,
                      })
                    )
                  }
                />
              </Flex>

              <CopyOutlined
                style={{ cursor: "copy" }}
                onClick={() =>
                  navigator.clipboard.writeText(
                    window.location.host +
                    "/" +
                    btoa(
                      JSON.stringify({
                        email: selectedUser?.email,
                        code_access: selectedUser?.code_access,
                      })
                    )
                  )
                }
              />
            </Flex>
          </div>
        </Modal>
      </ConfigProvider>
    </>
  );
}
