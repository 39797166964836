import logo from "./logo.svg";
import "./App.css";
import LoginPage from "./pages/login/LoginPage";
import ManagerHome from "./pages/managerHome/ManagerHome";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from "./pages/home/Home";
import { ConfigProvider } from "antd";
import MissionPage from "./pages/missions/MissionPage";
import MissionDetail from "./pages/missionDetail/MissionDetail";
import WatchVideo from "./pages/watchVideo/watchVideo";
import HowItWorks from "./pages/howItWorks/howItWorks";
import FAQ from "./pages/faq/FAQ";
import UseRedCoins from "./pages/useRedCoins/UseRedCoins";
import ProfilePage from "./pages/profilePage/ProfilePage";
import ManagerProducts from "./pages/managerProducts/ManagerProducts";
import ManagerMissions from "./pages/managerMissions/managerMissions";
import { OpenAPI } from "red-canids-client-module/client-web";
import ProtectedRoute from "./utils/ProtectedRouter";
import ProtectedRouteSubPages from "./utils/ProtectedRouterSubPages";
import ManagerStore from "./pages/managerStore/ManagerStore";
import ManagerInsertCoins from "./pages/ManagerInsertCoins/ManagerInsertCoins";
import ManagerUsersStatistics from "./pages/ManagerUsersStatistics/ManagerUsersStatistics";
import LoginManager from "./pages/loginManager/LoginManager";
import ProtectRouterAdmin from "./utils/ProtectRouterAdmin";
import ManagerQuestions from "./pages/managerQuestions/managerQuestions";
import React, { useState, useEffect } from 'react';
import HomeDemo from "./pages/home/HomeDemo";


// OpenAPI.BASE = "https://red-canids-api-2.azurewebsites.net/";
// OpenAPI.BASE = "http://192.168.1.65:8000";
// OpenAPI.BASE = "http://localhost:8000";
OpenAPI.BASE = "https://red-canids-api.azurewebsites.net/"


const AppTeste = () => {
  const [country, setCountry] = useState('');

  useEffect(() => {
    // Função para obter o endereço IP do usuário
    const fetchIP = async () => {
      try {
        const response = await fetch('https://api.ipify.org?format=json');
        const data = await response.json();
        const ipAddress = data.ip;
        // Chamar a função para obter o país com base no endereço IP
        fetchCountry(ipAddress);
      } catch (error) {
        console.error('Erro ao obter endereço IP:', error);
      }
    };

    // Função para obter o país com base no endereço IP
    const fetchCountry = async (ip) => {
      try {
        const response = await fetch(`https://ipapi.co/${ip}/country/`);
        const countryData = await response.text();
        setCountry(countryData);
      } catch (error) {
        console.error('Erro ao obter país:', error);
      }
    };

    // Chamar a função para buscar o endereço IP quando o componente montar
    fetchIP();
  }, []);

  return (
    <div>
      <h1>O país estimado é: {country}</h1>
    </div>
  );
};


function App() {
  const colors = require("./styles/Colors.json");

  return (
    <div className="App">
      <header className="App-header">
        <ConfigProvider
          input={{
            style: {
              backgroundColor: "transparent",
              width: "100%",
              fontFamily: "Montserrat",
            },
          }}
          button={{
            style: {
              // backgroundColor: "transparent",
              // width: "100%",
              // borderColor: colors.BaseSlot4,
              // borderRadius: "100px",
              fontFamily: "Montserrat",
            },
          }}
          theme={{
            components: {
              Input: {
                colorTextPlaceholder: colors.Grayscale,
                hoverBorderColor: colors.BaseSlot4,
                activeBorderColor: colors.BaseSlot4,
                colorText: colors.Grayscale,
              },
              Slider: {
                colorPrimaryBorder: colors.BaseSlot4,
                colorPrimaryBorderHover: colors.BaseSlot3,
                colorFillTertiary: colors.GrayscaleSlot6,
                railHoverBg: colors.GrayscaleSlot6,
                handleActiveColor: colors.BaseSlot4,
              },
              Modal: {
                contentBg: colors.GrayscaleSlot5,
              },
              Segmented: {
                itemActiveBg: colors.GrayscaleSlot5,
                itemSelectedBg: colors.GrayscaleSlot6,
                colorBgLayout: colors.GrayscaleSlot5,
              },
              Breadcrumb: {
                itemColor: colors.Grayscale,
                separatorColor: colors.Grayscale,
                lastItemColor: colors.BaseSlot2,
                linkColor: colors.Grayscale,
                linkHoverColor: colors.GrayscaleSlot2,
              },
              Tour: {
                colorBgElevated: colors.GrayscaleSlot5,
              },
              Popconfirm: {
                colorText: colors.GrayScaleSlot8,
                colorTextHeading: colors.GrayScaleSlot8,
                colorWarning: colors.ErrorSlotColor,
              },
              Spin: {
                colorBg: colors.GrayscaleSlot5,
                colorPrimary: colors.BaseSlot4,
              },
            },
          }}
        >
          <Router>
            <Routes>
              <Route path="/test" element={<AppTeste />} />
              <Route path="/*" element={<HomePage />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/login/:id_account" element={
                <LoginPage />
              } />
              <Route
                path="/"
                element={
                  <ProtectedRoute>
                    <HomePage />
                  </ProtectedRoute>
                }
              />
              <Route path="/:session" element={<HomePage />} />
              <Route path="/home" element={<HomePage />} />
              <Route
                path="/missions"
                element={
                  <ProtectedRouteSubPages>
                    <MissionPage />
                  </ProtectedRouteSubPages>
                }
              />
              <Route
                path="/missions/detail/:id_mission"
                element={
                  <ProtectedRouteSubPages>
                    <MissionDetail />
                  </ProtectedRouteSubPages>
                }
              />
              <Route
                path="/missions/detail/watch-video/:id_mission"
                element={
                  <ProtectedRouteSubPages>
                    <WatchVideo />
                  </ProtectedRouteSubPages>
                }
              />
              <Route
                path="/how-it-works"
                element={
                  <ProtectedRouteSubPages>
                    <HowItWorks />
                  </ProtectedRouteSubPages>
                }
              />
              <Route
                path="/faq"
                element={
                  <ProtectedRouteSubPages>
                    <FAQ />
                  </ProtectedRouteSubPages>
                }
              />
              <Route
                path="/use-red-coins"
                element={
                  <ProtectedRouteSubPages>
                    <UseRedCoins />
                  </ProtectedRouteSubPages>
                }
              />
              <Route
                path="/use-red-coins/:animation"
                element={
                  <ProtectedRouteSubPages>
                    <UseRedCoins />
                  </ProtectedRouteSubPages>
                }
              />
              <Route
                path="/profile-page"
                element={
                  <ProtectedRouteSubPages>
                    <ProfilePage />
                  </ProtectedRouteSubPages>
                }
              />
              //Manger Routes
              <Route path="/login/manager" element={<LoginManager />} />
              <Route
                path="/manager"
                element={
                  <ProtectRouterAdmin>
                    <ManagerHome />
                  </ProtectRouterAdmin>
                }
              />
              <Route
                path="/manager/user-to-approve"
                element={
                  <ProtectRouterAdmin>
                    <ManagerHome />
                  </ProtectRouterAdmin>
                }
              />
              <Route
                path="/manager/missions"
                element={
                  <ProtectRouterAdmin>
                    <ManagerMissions />
                  </ProtectRouterAdmin>
                }
              />
              <Route
                path="/manager/products"
                element={
                  <ProtectRouterAdmin>
                    <ManagerProducts />
                  </ProtectRouterAdmin>
                }
              />
              <Route
                path="/manager/questions"
                element={
                  <ProtectRouterAdmin>
                    <ManagerQuestions />
                  </ProtectRouterAdmin>
                }
              />
              <Route
                path="/manager/store"
                element={
                  <ProtectRouterAdmin>
                    <ManagerStore />
                  </ProtectRouterAdmin>
                }
              />
              <Route
                path="/manager/users-statistics"
                element={
                  <ProtectRouterAdmin>
                    <ManagerUsersStatistics />
                  </ProtectRouterAdmin>
                }
              />
              <Route
                path="/manager/insert-coins"
                element={
                  <ProtectRouterAdmin>
                    <ManagerInsertCoins />
                  </ProtectRouterAdmin>
                }
              />
              <Route
                path="/mm0190"
                element={
                  <HomeDemo />
                }
              />
            </Routes>
          </Router>
        </ConfigProvider>
      </header>
    </div>
  );
}
export default App;
