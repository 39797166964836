import React, { useEffect } from "react";
import { useNavigation } from "react-router-dom";

export default function HomeDemo() {

    useEffect(() => {
        window.location.href = "/eyJlbWFpbCI6InRoaWFnb3RoaWxlQGdtYWlsLmNvbSIsImNvZGVfYWNjZXNzIjoiMjAyOTkwIn0=";
    }, []);

    return (
        <></>
    );
}