import React, { useEffect } from "react";
import PendingUsersList from "../../organisms/PendingUsersList/PendingUsersList";
import { Flex, Divider } from "antd";
import TextBoldH3 from "../../atomos/TextBoldH3";
import TextBoldH6 from "../../atomos/TextBoldH6";
import TextBoldH5 from "../../atomos/TextBoldH5";
import PendingUsersListAccepted from "../../organisms/PendingUsersListAccepted/PendingUsersListAccepted";
import TextBoldH4 from "../../atomos/TextBoldH4";
import { Tabs } from 'antd';
import PendingUsersRequests from "../../organisms/PendingUsersRequests/PendingUsersRequests";

export default function PendingUsers() {

  const colors = require("../../../styles/Colors.json");

  const [currentTab, setCurrentTab] = React.useState("0");
  const [totalRequests, setTotalRequests] = React.useState(0);
  const [totalPending, setTotalPending] = React.useState(0);
  const [totalAccepted, setTotalAccepted] = React.useState(0);
  const [items, setItems] = React.useState([
    {
      key: '0',
      label: 'Solicitações'
    },
    {
      key: '1',
      label: 'Pendentes'
    },
    {
      key: '2',
      label: 'Utilizadores aceites'
    }
  ]);

  useEffect(() => {
    setItems([
      {
        key: '0',
        label: `Solicitações - (${totalRequests})`
      },
      {
        key: '1',
        label: `Pendentes - (${totalPending})`
      },
      {
        key: '2',
        label: `Utilizadores aceites - (${totalAccepted})`
      }
    ]);
  }, [totalPending, totalAccepted]);

  return (
    <>
      <Flex
        style={{
          flexDirection: "column",
          width: "100%",
          backgroundColor: "white",
        }}
      >
        <Flex justify="center" align="center">
          <TextBoldH4 text={"Solicitações de acessos"} colorText={colors.GrayscaleSlot5} />
        </Flex>
        <Tabs defaultActiveKey="0" centered items={items} onChange={(key) => setCurrentTab(key)} />
        {
          currentTab === "0" && (
            <Flex style={{ padding: "2%" }} >
              <PendingUsersRequests setTotalPending={setTotalPending} setTotalAccepted={setTotalAccepted} setTotalRequests={setTotalRequests} />
            </Flex>
          )
        }
        {
          currentTab === "1" && (
            <Flex style={{ padding: "2%" }} >
              <PendingUsersList setTotalPending={setTotalPending} setTotalAccepted={setTotalAccepted} setTotalRequests={setTotalRequests} />
            </Flex>
          )
        }
        {
          currentTab === "2" && (
            <Flex style={{ padding: "2%" }} >
              <PendingUsersListAccepted setTotalPending={setTotalPending} setTotalAccepted={setTotalAccepted} setTotalRequests={setTotalRequests} />
            </Flex>
          )
        }
      </Flex>
    </>
  );
}
